import { AiFillStar } from 'react-icons/ai';
import { BsLinkedin, BsFacebook, BsInstagram } from 'react-icons/bs';
import { Card } from '@/components/ui/card';
const SocialProof = () => {
  return (
    <div className="w-full max-w-4xl mt-10 mx-auto mb-0 bg-red-500/0 flex flex-col md:flex-row justify-center gap-4">
      <Card className="bg-muted/50 dark:bg-card flex flex-col justify-center items-center w-full md:w-1/2 p-6 rounded-lg">
        <h2 className="text-center text-base font-semibold leading-8 text-primary">3M+ photos generated</h2>
        <div className="flex mb-3">
          <AiFillStar className="text-amber-500" size={24} />
          <AiFillStar className="text-amber-500" size={24} />
          <AiFillStar className="text-amber-500" size={24} />
          <AiFillStar className="text-amber-500" size={24} />
          <AiFillStar className="text-amber-500" size={24} />
        </div>

        <div className="flex -space-x-2 overflow-hidden">
          <img
            className="inline-block h-10 w-10 rounded-full ring-4 ring-muted"
            src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/d3ad4f91-64c5-48b6-a632-5b4256061400/public"
            alt=""
          />
          <img
            className="inline-block h-10 w-10 rounded-full ring-4 ring-muted"
            src="https://imagedelivery.net/9sCnq8t6WEGNay0RAQNdvQ/7edd2eed-7ce2-493c-9f78-98062f915900/public"
            alt=""
          />
          <img
            className="inline-block h-10 w-10 rounded-full ring-4 ring-muted"
            src="https://storage.prompt-hunt.workers.dev/0e215b6b-3b00-4ba1-835a-309ec76cf538"
            alt=""
          />
          <img
            className="inline-block h-10 w-10 rounded-full ring-4 ring-muted"
            src="https://storage.prompt-hunt.workers.dev/4e4ddd7d-e997-48fd-9182-7048db2262ae"
            alt=""
          />
        </div>
      </Card>
      <Card className="bg-muted/50 dark:bg-card w-full md:w-1/2 p-6 rounded-lg">
        <h2 className="text-center text-base font-semibold leading-8 text-primary">Perfect to use on</h2>
        <div className="mx-auto grid grid-cols-3 w-fit gap-4 text-xs font-normal">
          <div className="p-2 flex flex-col gap-2 items-center">
            <BsLinkedin size={40} className="w-full object-contain col-span-1 opacity-20" />
            <div>LinkedIn</div>
          </div>
          <div className="p-2 flex flex-col gap-2 items-center">
            <BsFacebook size={40} className="w-full object-contain col-span-1 opacity-20" />
            <div>Facebook</div>
          </div>
          <div className="p-2 flex flex-col gap-2 items-center">
            <BsInstagram size={40} className="w-full object-contain col-span-1 opacity-20" />
            <div>Instagram</div>
          </div>
        </div>
      </Card>
    </div>
  );
};

export default SocialProof;
